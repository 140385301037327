<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable
    transition="dialog-transition"
  >
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Time Slots</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" small fab @click.stop="dialog=false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <div class="text-end">
          <v-btn
            color="color3"
            text
            @click.stop="toggleWindow"
            x-small
          >{{window === 1 ? 'wizard' : 'manual'}}</v-btn>
        </div>
        <v-window
          v-model="window"
        >
          <v-window-item :key="0">
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-combobox
                    dense
                    label="Slots"
                    v-model="slots"
                    :items="[1,2,3,4,5,6,7,8,9,10,11,12]"
                    type="number"
                  ></v-combobox>
                </v-col>
                <v-col cols="8">
                  <v-combobox
                    dense
                    label="Minutes per Slot"
                    v-model="length"
                    :items="mins"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-card class="px-2" flat>
                  <div>First Time Slot</div>
                  <v-row>
                    <v-col cols="4">
                      <v-autocomplete
                        dense
                        v-model="start.hour"
                        :items="['1','2','3','4','5','6','7','8','9','10','11','12']"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        dense
                        v-model="start.min"
                        :items="mins"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4">
                      <v-autocomplete
                        dense
                        v-model="start.ap"
                        :items="['AM', 'PM']"
                      ></v-autocomplete>
                    </v-col>

                  </v-row>
                </v-card>
              </v-row>
            </v-card-text>
          </v-window-item>
          <v-window-item :key="1">
            <v-card-text>
              <v-row v-for="(t, i) in times" :key="i">
                <v-col cols="4">
                  <v-autocomplete
                    dense
                    v-model="t.hour"
                    :items="['1','2','3','4','5','6','7','8','9','10','11','12']"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    dense
                    v-model="t.min"
                    :items="mins"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4">
                  <v-autocomplete
                    dense
                    v-model="t.ap"
                    :items="['AM', 'PM']"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-window-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success white--text"
              fab small
              @click="sync"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-window>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  props: ['timeSlots'],
  data () {
    return {
      window: 0,
      dialog: false,
      menu2: false,
      slots: 4,
      length: 60,
      times: [
        { hour: '6', min: '00', ap: 'PM' },
        { hour: '7', min: '00', ap: 'PM' },
        { hour: '8', min: '00', ap: 'PM' },
        { hour: '9', min: '00', ap: 'PM' }
      ],
      inited: false
    }
  },
  computed: {
    start () {
      return this.times && this.times[0]
    },
    mins () {
      return Array.from({ length: 60 }, (_, i) => i > 9 ? `${i}` : `0${i}`)
    },
    dto () {
      return this.times.map(t => `${t.hour}:${t.min} ${t.ap}`)
    }
  },
  methods: {
    open () {
      this.window = 0
      this.initSlots()
      this.dialog = true
    },
    calcSlots () {
      if (!this.inited) return
      const m = moment(this.dto[0], 'h:mm A')
      const r = []
      for (let x = 0; x < this.slots; x++) {
        x > 0 && m.add(this.length, 'm')
        r.push({
          hour: m.format('h'),
          min: m.format('mm'),
          ap: m.format('A')
        })
      }
      this.times = r
    },
    initSlots () {
      if (this.timeSlots && this.timeSlots.length) {
        this.times = this.timeSlots.map(t => {
          var x = moment(t, 'h:mm A')
          return {
            hour: x.format('h'),
            min: x.format('mm'),
            ap: x.format('A')
          }
        })
      } else {
        this.times = [
          { hour: '6', min: '00', ap: 'PM' },
          { hour: '7', min: '00', ap: 'PM' },
          { hour: '8', min: '00', ap: 'PM' },
          { hour: '9', min: '00', ap: 'PM' }
        ]
      }
      this.slots = this.times.length
      if (this.times.length > 1) {
        var difs = new Set()
        this.dto.forEach((t, i) => {
          if (i > 0) {
            const a = moment(this.dto[i], 'h:mm A')
            const b = moment(this.dto[i - 1], 'h:mm A')
            difs.add(a.diff(b, 'm'))
          }
        })
        this.length = [...difs][0]
        if (difs.size !== 1) {
          this.window = 1
        }
      }
      this.inited = true
    },
    sync () {
      if (this.window === 0) this.calcSlots()
      this.$emit('update', this.dto)
      this.dialog = false
    },
    toggleWindow () {
      this.window = this.window === 0 ? 1 : 0
    }
  },
  watch: {
    slots: 'calcSlots',
    length: 'calcSlots'
  }
}
</script>
